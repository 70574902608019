<script>
import { wpService } from "@/services/wp";
export default {
  props: {
    title: {
      default: "Cambio de correo electrónico",
      type: String,
    },
  },

  data() {
    return {
      new_email: "",
      new_email_confirmation: "",
      password: "",
      validate_email: false,
      validate_confirmation: false,
      validate_password: false,
    };
  },

  computed: {
    invalid() {
      let invalid = [];
      if (this.err_email) invalid.push("err_email");
      if (this.err_confirm) invalid.push("err_confirm");
      if (this.err_password) invalid.push("err_password");
      return invalid;
    },
    err_email() {
      const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.validate_email && !regex.test(this.new_email)) {
        return this.new_email.length
          ? "Correo electrónico no válido."
          : "Introduce un correo electrónico.";
      }
      return null;
    },
    err_confirm() {
      if (this.validate_confirmation) {
        const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!this.new_email_confirmation.length) {
          return "Confirme el correo electrónico.";
        } else if (!regex.test(this.new_email_confirmation)) {
          return "Correo electrónico no válido.";
        } else if (this.new_email !== this.new_email_confirmation) {
          return "El correo electrónico y la confirmación del correo no coinciden, por favor, revíselos.";
        }
      }
      return null;
    },
    err_password() {
      if (this.validate_password && !this.password.length) {
        return "Por favor, introduzca su contraseña actual.";
      }
      return null;
    },
  },

  methods: {
    isWrong(key) {
      return this.invalid.indexOf(key) != -1;
    },
    submit() {
      this.validate_email = true;
      this.validate_password = true;
      this.validate_confirmation = true;

      if (this.invalid.length) return;
      const loadingComponent = this.$buefy.loading.open({
        parent: this.$root,
      });
      return wpService.account
        .emailChange(this.password, this.new_email)
        .then(() => {
          this.$parent.close();
          this.$buefy.toast.open({
            message: "Se actualizó su correo electrónico.",
            type: "is-success",
          });
        })
        .catch(error => {
          console.log(error);
          let message = "Algo anda mal.";
          if (error.code === "wrong_password")
            message = "La contraseña actual no es correcta. Intente de nuevo.";
          else if (error.code === "email_reserved")
            message =
              "Lo sentimos, esta dirección de correo electrónico ya está reservada, pero puede estar disponible en un par de días.";
          else if (error.message) message = error.message;
          this.$buefy.dialog.alert({
            // title: "Title Alert",
            type: "is-danger",
            message: message,
            confirmText: "Ok",
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
  },
};
</script>

<template>
  <form id="email-change" class="modal-card" action="" @submit.prevent="submit">
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <b-field
        label="Nuevo correo electrónico:"
        :message="err_email"
        :type="{ 'is-danger': !!err_email }"
      >
        <b-input
          id="email"
          name="email"
          expanded
          size="is-medium"
          v-model="new_email"
          type="email"
          v-on:blur="validate_email = true"
        />
      </b-field>
      <b-field
        label="Confirmar nuevo correo electrónico:"
        :message="err_confirm"
        :type="{ 'is-danger': !!err_confirm }"
      >
        <b-input
          id="email_confirmation"
          name="email_confirmation"
          expanded
          size="is-medium"
          v-model="new_email_confirmation"
          type="email"
          v-on:blur="validate_confirmation = true"
        />
      </b-field>
      <b-field
        label="Contraseña:"
        :message="err_password"
        :type="{ 'is-danger': !!err_password }"
      >
        <b-input
          id="password"
          name="password"
          expanded
          size="is-medium"
          v-model="password"
          type="password"
          password-reveal
          autocomplete="off"
          v-on:blur="validate_password = true"
        />
      </b-field>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Cerrar
      </button>
      <button class="button is-primary">Guardar</button>
    </footer>
  </form>
</template>

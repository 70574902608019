<script>
import { mapGetters } from "vuex";
import { wpService } from "@/services/wp";
import modalPassword from "@/components/account-patch-password";
import modalEmail from "@/components/account-patch-email";
export default {
  name: "account-configuration",

  metaInfo: {
    title: "Mi Configuración",
  },

  data() {
    return {
      loading: false,
    };
  },

  computed: {
    ...mapGetters("session", ["currentAccount"]),
    registered() {
      if (!this.currentAccount) return "";
      let d = new Date(this.currentAccount.user_registered * 1000);
      let year = d.getFullYear();
      let month = d.getMonth();
      let day = String(d.getDay());
      return `${year}-${month}-${day.padStart(2, "0")}`;
    },
  },

  methods: {
    patch(body) {
      this.loading = true;
      return wpService.account.accountPatch(body).finally(() => {
        this.loading = false;
      });
    },
    patchPassword() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalPassword,
        parent: this,
        hasModalCard: true,
        trapFocus: true,
        props: {
          title: "Cambio de contraseña",
        },
        events: {
          submit: value => {
            // console.log(value);
            this.patch({ password: value }).then(() => {
              this.$buefy.toast.open({
                message: "Se actualizó su contraseña.",
                type: "is-success",
              });
            });
          },
        },
      });
    },
    patchEmail() {
      this.$buefy.modal.open({
        customClass: "dialog",
        component: modalEmail,
        parent: this,
        hasModalCard: true,
        trapFocus: true,
      });
    },
  },
};
</script>

<template>
  <div id="account-configuration" class="ui_padding">
    <b-loading :active="loading" />
    <div class="container">
      <h1 class="page__title">Mi Configuración</h1>
      <div id="account_details" class="section">
        <h3 class="section__title">Detalles del usuario</h3>
        <div class="ibox">
          <div class="databox">
            <span class="da__label">Correo electrónico</span>
            <span class="da__value">{{ currentAccount.user_email }}</span>
            <a href="" class="da__option" @click.prevent="patchEmail">
              <span class="text">Editar</span>
              <b-icon icon="pen" />
            </a>
          </div>
          <div class="databox">
            <span class="da__label">Contraseña</span>
            <span class="da__value">*********</span>
            <a href="" class="da__option" @click.prevent="patchPassword">
              <span class="text">Editar</span>
              <b-icon icon="pen" />
            </a>
          </div>
          <div class="databox">
            <span class="da__label">Fecha de registro</span>
            <span class="da__value">{{ registered }}</span>
          </div>
        </div>
      </div>
      <div id="account_preferences" class="section">
        <h3 class="section__title">Preferencias</h3>
        <div class="ibox ibox--emails">
          <h4 class="ibox__title">Preferencias de correo electrónico</h4>
          <div class="content">
            <p>¿Qué tipo de comunicaciones le gustaría recibir de Sitio.do?</p>
          </div>
          <div class="databox">
            <span class="da__label"
              >Noticias, tips y contenido seleccionado por profesionales.</span
            >
            <b-switch outlined />
          </div>
          <div class="databox">
            <span class="da__label"
              >Ofertas especiales y precios increíbles.</span
            >
            <b-switch outlined />
          </div>
          <div class="databox">
            <span class="da__label"
              >Educación y actualizaciones de productos.</span
            >
            <b-switch outlined />
          </div>
        </div>
      </div>
      <div id="account_delete" class="section">
        <h3 class="section__title">Borrar cuenta</h3>
        <div class="ibox">
          <div class="content">
            <p>
              De esta forma, se eliminarán de manera permanente todos sus datos
              personales.
            </p>
            <button class="button is-rounded is-small is-danger">
              <b>Eliminar mi cuenta</b>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.profile__photo {
  img {
    width: 100%;
    max-width: 10rem;
    border-radius: 1rem;
  }
}
#account-configuration {
  .ibox {
    .databox {
      &:not(:last-child) {
        border-bottom: 1px solid rgba(12, 18, 28, 0.12);
      }
      > * {
        // display: block;
        padding: 0.75rem 1rem;
      }
    }
  }

  @media (max-width: 63.9375em) {
    #account_details {
      .databox {
        padding: 0.5rem 0;
        display: grid;
        grid-template-columns: 1fr 3rem;
        grid-template-areas: "a b" "c b";
        align-items: center;
        :nth-child(3) {
          text-align: right;
        }
        > * {
          padding: 0;
        }
        .da__label {
          grid-area: a;
          font-weight: 600;
        }
        .da__value {
          grid-area: c;
        }
        .da__option {
          grid-area: b;
          .icon {
            width: 3rem;
            height: 3rem;
          }
          .text {
            display: none;
          }
        }
      }
    }
  }
  @media (min-width: 64em) {
    #account_details {
      // padding: 0 1rem;
      .databox {
        display: grid;
        grid-template-columns: 2fr 3fr 1fr;
        :nth-child(3) {
          text-align: right;
        }
        .da__option .icon {
          display: none;
        }
      }
    }
  }

  .ibox--emails {
    .databox {
      width: 100%;
      display: flex;
      justify-content: space-between;
      > * {
        padding: 0.75rem 0;
      }
      .da__label {
        padding-right: 1rem;
      }
      .control-label {
        display: none;
      }
    }
  }
}
</style>

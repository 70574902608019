<script>
import { wpService } from "@/services/wp";
export default {
  props: {
    title: {
      default: "Cambio de contraseña",
      type: String,
    },
  },

  data() {
    return {
      current_password: "",
      password: "",
      confirm_password: "",
      validate_current: false,
      validate_password: false,
      validate_confirmation: false,
    };
  },

  computed: {
    invalidPassword() {
      let invalidPassword = [];

      if (!this.current_password.length) invalidPassword.push("err_current");

      if (this.password.length < 8 || this.password.length > 24)
        invalidPassword.push("err_password");

      if (this.password !== this.confirm_password)
        invalidPassword.push("err_confirm");

      return invalidPassword;
    },
    err_current() {
      return this.validate_current && this.isWrong("err_current")
        ? "Por favor, introduzca su contraseña actual."
        : null;
    },
    err_password() {
      return this.validate_password && this.isWrong("err_password")
        ? "La nueva contraseña debe contener de 8 a 24 caracteres."
        : null;
    },
    err_confirm() {
      return this.validate_confirmation && this.isWrong("err_confirm")
        ? "La contraseña y la confirmación de contraseña no coinciden, por favor, revíselas."
        : null;
    },
  },

  methods: {
    isWrong(key) {
      return this.invalidPassword.indexOf(key) != -1;
    },
    submit() {
      this.validate_current = true;
      this.validate_password = true;
      this.validate_confirmation = true;

      if (this.invalidPassword.length) return;
      // this.$emit("submit", this.password);
      const loadingComponent = this.$buefy.loading.open({
        parent: this.$root,
      });
      return wpService.account
        .passwordChange(this.current_password, this.password)
        .then(() => {
          this.$parent.close();
          this.$buefy.toast.open({
            message: "Se actualizó su contraseña.",
            type: "is-success",
          });
        })
        .catch(error => {
          console.log(error);
          let message = "Algo anda mal.";
          if (error.code === "wrong_password")
            message = "La contraseña actual no es correcta. Intente de nuevo.";
          this.$buefy.dialog.alert({
            // title: "Title Alert",
            type: "is-danger",
            message: message,
            confirmText: "Ok",
          });
        })
        .finally(() => {
          loadingComponent.close();
        });
    },
  },
};
</script>

<template>
  <form
    id="password-change"
    class="modal-card"
    action=""
    @submit.prevent="submit"
  >
    <header class="modal-card-head">
      <p class="modal-card-title">{{ title }}</p>
    </header>
    <section class="modal-card-body">
      <b-field
        label="Contraseña actual:"
        :message="err_current"
        :type="{ 'is-danger': !!err_current }"
      >
        <b-input
          id="current_password"
          name="current_password"
          expanded
          size="is-medium"
          v-model="current_password"
          type="password"
          password-reveal
          autocomplete="off"
          v-on:blur="validate_current = true"
        />
      </b-field>
      <hr />
      <!-- <div class="content">
        <p>
          Requisitos para nuevas contraseñas:
        </p>
        <ul>
          <li :class="isWrong('err_password') ? 'has-text-danger' : ''">
            <small>Debe contener de 8 a 24 caracteres</small>
          </li>
          <li :class="isWrong('err_confirm') ? 'has-text-danger' : ''">
            <small>Ambas contraseñas deben coincidir</small>
          </li>
        </ul>
      </div> -->
      <b-field
        label="Nueva contraseña:"
        :message="err_password"
        :type="{ 'is-danger': !!err_password }"
      >
        <b-input
          id="password"
          name="password"
          expanded
          size="is-medium"
          v-model="password"
          type="password"
          password-reveal
          v-on:blur="validate_password = true"
        />
      </b-field>
      <b-field
        label="Confirmar nueva contraseña:"
        :message="err_confirm"
        :type="{ 'is-danger': !!err_confirm }"
      >
        <b-input
          id="confirm_password"
          name="confirm_password"
          expanded
          size="is-medium"
          v-model="confirm_password"
          type="password"
          password-reveal
          v-on:blur="validate_confirmation = true"
        />
      </b-field>
      <small>* La nueva contraseña debe contener de 8 a 24 caracteres</small>
    </section>
    <footer class="modal-card-foot">
      <button class="button" type="button" @click="$parent.close()">
        Cerrar
      </button>
      <button class="button is-primary">Guardar</button>
    </footer>
  </form>
</template>
